import React from "react";
import { graphql, PageProps } from "gatsby";

import { Query, SanityTrainerStoriesPageConnection } from "@graphql-types";
import { Components } from "@components";
import SEO from "@shared/seo";
import { Container } from "@util/standard";
import Hero from "@shared/hero";
import { ProviderContainer } from "@global";
import { useStore } from "@state/store";

interface Data extends Query {
  altRegions: SanityTrainerStoriesPageConnection;
}

interface Props extends PageProps {
  data: Data;
}

export default function TrainerStoriesTemplate({ data, pageContext }: Props) {
  const { nodes } = data.allSanityTrainerStoriesPage;
  const { user } = useStore();

  const {
    password,
    components,
    hero,
    seo,
  } = nodes[0];
  const alternateRegions = data.altRegions.nodes.map(alt => alt.region);

  return (
    <div>
      <SEO seoData={seo} alternateRegions={alternateRegions} heroData={hero} slug={pageContext?.pagePath} />
      <ProviderContainer password={password} isAdmin={user?.isAdmin}>
        <Hero heroData={hero} />
        {components &&
          <Container flexDirection="column">
            {components.map(component => {
              if (component == null) return null;
              return (
                <Components key={component._key} data={component} />
              );
            })
            }
          </Container>
        }
      </ProviderContainer>
    </div>
  );
}

export const query = graphql`
  query TrainerQuery($region: String, $slug: String) {
    allSanityTrainerStoriesPage(filter: { 
      region: { iban: { eq: $region } } 
      slug: { current: { eq: $slug } }
    }) {
      nodes {
        password
        title
        region {
          ...sanityRegion
        }
        seo {
          ...sanitySeo
        }
        hero {
          ...sanityHero
        }
        components {
          ... on SanityPartnersBlock {
            ...sanityPartnersBlock
          }
          ... on SanityVideoSliderBlock {
            ...sanityVideoSliderBlock
          }
          ... on SanityImageSliderBlock {
            ...sanityImageSliderBlock
          }
          ... on SanityFullScreenContentBlock {
            ...sanityFullScreenContentBlock
          }
          ... on SanityImagesContentBlock {
            ...sanityImagesContentBlock
          }
          ... on SanityImageContentCarouselBlock {
            ...sanityImageContentCarouselBlock
          }
          ... on SanityFeaturedBlogsBlock {
            ...sanityFeaturedBlogsBlock
          }
          ... on SanityMediaContentBlock {
            ...sanityMediaContentBlock
          }
        }
      }
    }
    altRegions: allSanityTrainerStoriesPage {
      nodes {
        region {
          ...sanityRegion
        }
      }
    }
  }
`;
